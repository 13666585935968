import { Plan } from '@wix/ambassador-checkout-server/types';
import _ from 'lodash';
import {
  PricingPlan,
  ReservedLocationIds,
  Service,
  StaffMember,
} from '@wix/bookings-uou-types';
import { CalendarState } from '../../components/BookingCalendar/controller';
import { CalendarStatus } from '../../components/BookingCalendar/ViewModel/widgetViewModel/widgetViewModel';
import { FilterTypes } from '../../components/BookingCalendar/ViewModel/filterViewModel/filterViewModel';
import {
  BookingsQueryParams,
  CalendarOptions,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { EmptyStateType } from '../../components/BookingCalendar/ViewModel/emptyStateViewModel/emptyStateViewModel';
import { ServiceOptionsAndVariants } from '@wix/ambassador-bookings-catalog-v1-service-options-and-variants/types';
import {
  Preset,
  Optional,
  SlotsStatus,
  Preference,
  SelectedVariantOptions,
} from '../../types/types';
import { getValidPurchasedPricingPlansForService } from '../pricingPlans/pricingPlans';
import { Balance } from '@wix/ambassador-pricing-plan-benefits-server/types';
import { Booking } from '@wix/bookings-checkout-api';
import { getAvailableServicesByPreset } from './getAvailableServicesByPreset';
import { extractQueryParamValues } from '../queryParams/queryParams';
import { Experiments } from '@wix/yoshi-flow-editor';
import { SelectedBookingPreference } from '../bookingPreferences/bookingPreferences';
import { getVariantByChoiceIdAndOptionId } from '../dynamicPricing/dynamicPricing';

export function createInitialState({
  servicesInView,
  allCalendarBookableServices,
  wixSdkAdapter,
  staffMembers,
  rescheduleBookingDetails,
  initialErrors,
  allPurchasedPricingPlans,
  isPricingPlanInstalled,
  isUserLoggedIn,
  servicesVariants,
  preset,
  experiments,
  calendarOptionsQueryParamValue,
}: {
  servicesInView: Service[];
  allCalendarBookableServices?: Service[];
  wixSdkAdapter: WixOOISDKAdapter;
  staffMembers?: StaffMember[];
  rescheduleBookingDetails?: Booking;
  initialErrors: EmptyStateType[];
  allPurchasedPricingPlans?: Balance[];
  isPricingPlanInstalled: boolean;
  isUserLoggedIn: boolean;
  servicesVariants?: ServiceOptionsAndVariants[];
  preset: Preset;
  experiments?: Experiments;
  calendarOptionsQueryParamValue?: CalendarOptions;
}): CalendarState {
  const locationFilterOptions = initializeLocationFilterOptions({
    wixSdkAdapter,
    calendarOptionsQueryParamValue,
  });
  const staffFilterOptions = initializeStaffMembersFilterOptions({
    staffMembers,
    wixSdkAdapter,
    preset,
    servicesInView,
    experiments,
  });
  const purchasedPricingPlans = initializePurchasedPricingPlans({
    servicesInView,
    allPurchasedPricingPlans,
    isPricingPlanInstalled,
    isUserLoggedIn,
  });
  const selectedDate = initializeSelectedDate(wixSdkAdapter);

  const serviceVariantsMap = createServicesVariantsMap(servicesVariants);

  const servicesSelections =
    mapCalendarOptionsQueryParamValueToServicesSelections({
      serviceVariantsMap,
      calendarOptionsQueryParamValue,
    });

  return {
    calendarStatus: CalendarStatus.INITIALIZING,
    slotsStatus: SlotsStatus.LOADING,
    servicesInView,
    allCalendarBookableServices,
    selectedDate,
    selectedTimezone: undefined,
    selectedTime: undefined,
    availableSlots: undefined,
    selectableSlotsAtSelectedTime: undefined,
    selectedRange: undefined,
    availableSlotsPerDay: undefined,
    selectedBookingPreferences: [],
    calendarErrors: [],
    rescheduleBookingDetails,
    dialog: undefined,
    filterOptions: {
      [FilterTypes.SERVICE]: [],
      [FilterTypes.LOCATION]: locationFilterOptions,
      [FilterTypes.STAFF_MEMBER]: staffFilterOptions,
    },
    initialErrors,
    purchasedPricingPlans,
    isUserLoggedIn,
    selectedVariantsOptions: [],
    serviceVariantsMap,
    servicesSelections,
  };
}

const mapCalendarOptionsQueryParamValueToServicesSelections = ({
  calendarOptionsQueryParamValue,
  serviceVariantsMap,
}: {
  calendarOptionsQueryParamValue?: CalendarOptions;
  serviceVariantsMap: CalendarState['serviceVariantsMap'];
}): CalendarState['servicesSelections'] => {
  if (!calendarOptionsQueryParamValue?.services) {
    return [];
  }

  return calendarOptionsQueryParamValue.services.map(
    ({ id, resources, variants }) => {
      const serviceId = id;
      const serviceVariants = serviceVariantsMap[serviceId];
      let selectedVariantsOptions: SelectedVariantOptions[] = [];
      const selectedPreferences: SelectedBookingPreference[] = [
        {
          key: Preference.STAFF_MEMBER,
          value: resources[0],
        },
      ];

      if (serviceVariants) {
        let optionId: string;
        let choiceId: string;

        if (variants) {
          const calendarOptionsSelectedVariant = variants[0];
          choiceId = calendarOptionsSelectedVariant.choices[0].custom;
          optionId = calendarOptionsSelectedVariant.choices[0].optionId;
        } else {
          choiceId = resources[0];
          optionId = serviceVariants.variants?.values?.find(
            ({ choices }) => choices?.[0].staffMemberId === resources[0],
          )?.choices?.[0].optionId!;
        }

        const selectedVariant = getVariantByChoiceIdAndOptionId(
          serviceVariants,
          choiceId,
          optionId,
        );

        selectedVariantsOptions = [
          {
            choices: selectedVariant?.choices!,
            numberOfParticipants: 1,
            price: selectedVariant?.price!,
          },
        ];
      }

      return {
        serviceId,
        selectedPreferences,
        selectedVariantsOptions,
      };
    },
  );
};

const createServicesVariantsMap = (
  serviceVariants?: ServiceOptionsAndVariants[],
) => {
  if (!serviceVariants) {
    return {};
  }

  return serviceVariants.reduce<Record<string, ServiceOptionsAndVariants>>(
    (acc, serviceVariant) => {
      acc[serviceVariant.serviceId!] = serviceVariant;
      return acc;
    },
    {},
  );
};

const initializeLocationFilterOptions = ({
  wixSdkAdapter,
  calendarOptionsQueryParamValue,
}: {
  wixSdkAdapter: WixOOISDKAdapter;
  calendarOptionsQueryParamValue?: CalendarOptions;
}): string[] => {
  const preSelectedLocations = calendarOptionsQueryParamValue?.location
    ? [calendarOptionsQueryParamValue.location]
    : extractQueryParamValues(wixSdkAdapter, BookingsQueryParams.LOCATION);

  return preSelectedLocations.filter(
    (location) => location !== ReservedLocationIds.OTHER_LOCATIONS,
  );
};

const initializeStaffMembersFilterOptions = ({
  servicesInView,
  wixSdkAdapter,
  preset,
  staffMembers,
  experiments,
}: {
  servicesInView: Service[];
  wixSdkAdapter: WixOOISDKAdapter;
  preset: Preset;
  staffMembers?: StaffMember[];
  experiments?: Experiments;
}): string[] => {
  const staffFilterOptions: string[] = [];

  if (staffMembers) {
    const staffMemberId = staffMembers?.[0]?.id;
    if (staffMemberId) {
      const services = experiments?.enabled(
        'specs.bookings.calendarServicesDropdownRefactor',
      )
        ? servicesInView
        : getAvailableServicesByPreset({
            servicesInView,
            preset,
          });
      const isStaffMemberConnectedToService = services.some((service) =>
        _.find(service?.staffMembers, ({ id }) => id === staffMemberId),
      );
      if (isStaffMemberConnectedToService) {
        staffFilterOptions.push(staffMemberId);
      }
    }
  } else {
    const resourcesIds = extractQueryParamValues(
      wixSdkAdapter,
      BookingsQueryParams.RESOURCE,
    );
    if (resourcesIds.length) {
      const services = experiments?.enabled(
        'specs.bookings.calendarServicesDropdownRefactor',
      )
        ? servicesInView
        : getAvailableServicesByPreset({
            servicesInView,
            preset,
          });
      resourcesIds.forEach((resourceId) => {
        const isStaffMemberConnectedToService = services.some((service) =>
          _.find(service?.staffMembers, ({ id }) => id === resourceId),
        );
        if (isStaffMemberConnectedToService) {
          staffFilterOptions.push(resourceId);
        }
      });
    }
  }

  return staffFilterOptions;
};

const initializePurchasedPricingPlans = ({
  servicesInView,
  allPurchasedPricingPlans,
  isPricingPlanInstalled,
  isUserLoggedIn,
}: {
  servicesInView: Service[];
  allPurchasedPricingPlans?: Balance[];
  isPricingPlanInstalled: boolean;
  isUserLoggedIn: boolean;
}): Plan[] => {
  let purchasedPricingPlans: Plan[] = [];
  let servicePricingPlans: PricingPlan[] = [];

  if (isPricingPlanInstalled) {
    if (isUserLoggedIn) {
      servicePricingPlans =
        (servicesInView.length &&
          servicesInView[0].payment.pricingPlanInfo?.pricingPlans) ||
        [];
      purchasedPricingPlans = getValidPurchasedPricingPlansForService({
        servicePricingPlans,
        allPurchasedPricingPlans: allPurchasedPricingPlans || [],
      });
    }
  }
  return purchasedPricingPlans;
};

const initializeSelectedDate = (
  wixSdkAdapter: WixOOISDKAdapter,
): Optional<string> => {
  return wixSdkAdapter.getUrlQueryParamValue(BookingsQueryParams.DATE);
};
