import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';
import {
  DialogState,
  DialogType,
} from '../../ViewModel/dialogViewModel/dialogViewModel';
import { getTrackingInfoForCalendarNextButtonClick } from '@wix/bookings-analytics-adapter';
import { bookingsContactInfoSaveSuccess } from '@wix/bi-logger-wixboost-ugc/v2';
import {
  mapFlowTypeToBiProperties,
  mapStateToProperties,
} from '../../../../utils/bi/mappers';
import { isBookingsEnabled } from '../../../../utils/serviceUtils/serviceUtils';
import { Service } from '@wix/bookings-uou-types';
import {
  deprecatedGetDynamicPricingInfo,
  getDynamicPricingInfo,
} from '../../../../utils/dynamicPricing/dynamicPricing';
import { BookingsQueryParams } from '@wix/bookings-adapter-ooi-wix-sdk';
import { isSlotNoBookFlow } from '../../../../utils/slotAvailability/slotAvailability';
import { CalendarStatus } from '../../ViewModel/widgetViewModel/widgetViewModel';

export type NavigateToNextPage = ({
  selectedSlots,
  isWaitingListFlow,
}: {
  selectedSlots: SlotAvailability[];
  isWaitingListFlow: boolean;
}) => void;

export function createNavigateToNextPageAction(
  actionFactoryParams: ActionFactoryParams<CalendarState, CalendarContext>,
): NavigateToNextPage {
  return async ({ selectedSlots, isWaitingListFlow }) => {
    const { getControllerState, context } = actionFactoryParams;
    const [state, setState] = getControllerState();
    const { wixSdkAdapter, biLogger, activeFeatures, preset, experiments } =
      context;
    const { servicesInView, selectedTime, rescheduleBookingDetails } = state;

    const selectedSlot = selectedSlots[0];
    const selectedServicesIds =
      selectedSlot.nestedSlots!.length > 1
        ? selectedSlot.nestedSlots!.map((slot) => slot.serviceId!)
        : [selectedSlot.slot?.serviceId!];
    const selectedServices = servicesInView.filter((service) =>
      selectedServicesIds?.includes(service.id),
    )!;

    const isTemplateMode = wixSdkAdapter.isTemplateMode();

    const isRouterPrefixNavigationEnable = experiments.enabled(
      'specs.bookings.RouterPrefixNavigation',
    );
    const isBookingEnabled = isBookingsEnabled({
      servicesTypes: selectedServices.map((service) => service.info.type),
      activeFeatures: activeFeatures!,
      isTemplateMode,
    });
    const siteIsEcom =
      !experiments.enabled('specs.bookings.V1Shutdown') ||
      (await context.calendarApi.isEcomSite());
    if (isBookingEnabled && siteIsEcom) {
      const isNoBookFlow = isSlotNoBookFlow(selectedSlot);
      const isReschedulingFlow = !!rescheduleBookingDetails;
      const isBookingFormInstalled =
        await wixSdkAdapter.isBookingFormInstalled();

      void biLogger.report(
        bookingsContactInfoSaveSuccess({
          selectedSlot: selectedTime,
          ...mapFlowTypeToBiProperties({
            isWaitingList: isWaitingListFlow,
            isBookingFormInstalled,
            isNoBookFlow,
            properties: mapStateToProperties({ state, context }),
          }),
        }),
      );

      if (isNoBookFlow) {
        handleNoBookFlow({
          selectedService: selectedServices[0],
          context,
          isRouterPrefixNavigationEnable,
        });
      } else if (isReschedulingFlow) {
        handleReschedulingFlow(actionFactoryParams);
      } else if (isWaitingListFlow) {
        handleWaitingListFlow(actionFactoryParams);
      } else {
        await handleRegularBookFlow(
          actionFactoryParams,
          selectedSlot,
          selectedServices,
        );
      }
    } else {
      if (wixSdkAdapter.isPreviewMode() && !isBookingEnabled) {
        // only one service is supported in preview mode
        const serviceType = selectedServices[0].info.type;
        await wixSdkAdapter.openPreviewPremiumModal(serviceType, preset);
      }

      if (wixSdkAdapter.isSiteMode()) {
        setState({
          dialog: {
            type: siteIsEcom
              ? DialogType.PremiumViewer
              : DialogType.siteIsNotEcom,
            state: DialogState.IDLE,
          },
        });
      }
    }
  };
}

const handleWaitingListFlow = ({
  getControllerState,
}: ActionFactoryParams<CalendarState, CalendarContext>) => {
  const [, setState] = getControllerState();

  setState({
    dialog: {
      type: DialogType.JoinWaitlist,
      state: DialogState.IDLE,
    },
  });
};

const handleReschedulingFlow = ({
  getControllerState,
}: ActionFactoryParams<CalendarState, CalendarContext>) => {
  const [, setState] = getControllerState();

  setState({
    dialog: {
      type: DialogType.RescheduleConfirm,
      state: DialogState.IDLE,
    },
  });
};

const handleNoBookFlow = ({
  selectedService,
  context,
  isRouterPrefixNavigationEnable = false,
}: {
  selectedService: Service;
  context: CalendarContext;
  isRouterPrefixNavigationEnable?: boolean;
}) => {
  const { preset: referral, wixSdkAdapter } = context;
  const serviceSlug = selectedService!.info.slugs[0];

  void wixSdkAdapter.navigateToBookingsServicePage(
    serviceSlug,
    {
      referral,
    },
    isRouterPrefixNavigationEnable,
  );
};

const handleRegularBookFlow = async (
  {
    getControllerState,
    context,
  }: ActionFactoryParams<CalendarState, CalendarContext>,
  slotAvailability: SlotAvailability,
  selectedServices: Service[],
) => {
  const { wixSdkAdapter, businessInfo, experiments, preset, isCartEnabled } =
    context;
  const [state, setState] = getControllerState();
  const {
    selectedTimezone,
    selectedVariantsOptions,
    servicesSelections,
    selectedBookingPreferences,
  } = state;

  const isAnywherePublicDataOverridesEnabled =
    experiments &&
    experiments.enabled('specs.bookings.anywherePublicDataOverrides');

  const isRouterPrefixNavigationEnable = experiments.enabled(
    'specs.bookings.RouterPrefixNavigation',
  );
  const isMultiServiceAppointmentEnabled = experiments.enabled(
    'specs.bookings.multiServiceAppointment',
  );

  const serviceSlug = selectedServices[0]!.info.slugs[0];

  const continueShoppingUrl = isCartEnabled
    ? `/${wixSdkAdapter
        .getCurrentPath(isRouterPrefixNavigationEnable)
        .join('/')}?${BookingsQueryParams.TIMEZONE}=${state.selectedTimezone}`
    : undefined;

  const trackingInfo = getTrackingInfoForCalendarNextButtonClick({
    services: selectedServices,
    businessName: businessInfo?.name || '',
  });
  wixSdkAdapter.trackAnalytics(trackingInfo);

  const dynamicPriceInfo = isMultiServiceAppointmentEnabled
    ? getDynamicPricingInfo({
        selectedSlot: slotAvailability,
        servicesSelections: servicesSelections.length
          ? servicesSelections
          : [
              {
                selectedPreferences: selectedBookingPreferences,
                selectedVariantsOptions,
                serviceId: selectedServices[0].id,
              },
            ],
      })
    : deprecatedGetDynamicPricingInfo(selectedVariantsOptions);

  setState({ calendarStatus: CalendarStatus.NAVIGATING }, { immediate: true });

  void wixSdkAdapter.navigateToBookingsFormPage({
    slotAvailability,
    serviceSlug,
    dynamicPriceInfo,
    isAnywhereSpecEnable: isAnywherePublicDataOverridesEnabled,
    queryParams: {
      [BookingsQueryParams.REFERRAL]: preset,
      [BookingsQueryParams.TIMEZONE]: selectedTimezone!,
      ...(isCartEnabled
        ? { [BookingsQueryParams.CONTINUE_SHOPPING_URL]: continueShoppingUrl }
        : {}),
    },
  });
};
